import React, { useEffect, useState } from "react";
import ClassicTemplate from "./CVTemplates/ClassicTemplate";
import FunctionalTemplate from "./CVTemplates/FunctionalTemplate";
import CombinedTemplate from "./CVTemplates/CombinedTemplate";
import ModernTemplate from "./CVTemplates/ModernTemplate";
import { useDispatch, useSelector } from "react-redux";
import {
  addhonordetails,
  addlanguagedetails,
  addskillsdetails,
  deleteUserHonors,
  deleteUserLanguage,
  deleteUserSkill,
} from "../../store/profile/profileActions";
import DatePickerCustom from "../../core/components/DatePicker/DatePickerReact";
import moment from "moment";
import { getIndustryData } from "../../store/master/masterActions";
import YearPickerCustom from "../../core/components/DatePicker/YearPickerReact";
import { Input } from "reactstrap";
import { call } from "../../api/apiCall";
import DeleteModal from "../../core/components/modal/DeleteModal";
import { initPageLoad, stopPageLoad } from "../../store/loader/loaderActions";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import {
  addHonors,
  addInterest,
  addLanguage,
  addSkills,
  deleteHonors,
  deleteInterest,
  deleteInterests,
  deleteLanguage,
  deleteSkills,
  editBasicInfo,
  editHonors,
  editLanguage,
} from "../../store/cv/cvActions";
import ThumbnailResumePreview from "./ThumbnailResumePreview";

export const EditResumeSkill = ({
  pageNo,
  toggle,
  setPageNo,
  editCV,
  user,
  color,
  token,
  // skillBackground,
  // setSkillBackground,
  // interestBackground,
  loader,
  setinterestBackground,
  setPreviewCV,
  setPreviewResumeModal,
}) => {
  const { isPageLoading } = useSelector((state) => state.loader);
  const [skills, setSkills] = useState("");
  const [interest, setInterest] = useState("");
  const dispatch = useDispatch();
  const [skillError, setSkillError] = useState("");
  const [interestError, setInterestError] = useState("");

  const { isLoading, cvTypes } = useSelector((state) => state.cv);
  // const handleSkill = (e) => {
  //   if (skills?.trim()?.length >= 3) {
  //     if (e.key == "Enter") {
  //       dispatch(initPageLoad());
  //       dispatch(addSkills(skills, editCV));
  //       setSkills("");

  //       dispatch(stopPageLoad());
  //     }
  //   } else {
  //     setSkillError("Please enter at least three characters.");
  //   }
  // };

  const handleSkill = (e) => {
    if (skills.trim().length >= 3) {
      console.log(skills);
      if (e.key === "Enter") {
        const existingSkills = user?.exps_and_skills?.map((item) =>
          typeof item === "object"
            ? item.skills.toLowerCase()
            : item.toLowerCase()
        );

        if (!existingSkills.includes(skills.trim().toLowerCase())) {
          dispatch(initPageLoad());
          dispatch(addSkills(skills.trim(), editCV));
          setSkills("");
          setSkillError("");
          dispatch(stopPageLoad());
        } else {
          setSkillError("This skill is already added.");
        }
      }
    } else {
      setSkillError("Please enter at least three characters.");
    }
  };

  const handleInterest = (e) => {
    if (interest.trim().length >= 3) {
      if (e.key === "Enter") {
        const existinginterest = user?.interests?.map((item) =>
          item.toLowerCase()
        );

        if (!existinginterest.includes(interest.trim().toLowerCase())) {
          dispatch(initPageLoad());
          dispatch(addInterest(interest.trim(), editCV));
          setInterest("");
          setInterestError("");
          dispatch(stopPageLoad());
        } else {
          setInterestError("This interest is already added.");
        }
      }
    } else {
      setInterestError("Please enter at least three characters.");
    }
  };

  const deleteSkill = (id) => {
    dispatch(deleteSkills(id, editCV));
  };

  const deleteInterest = (id) => {
    dispatch(deleteInterests(id, editCV));
  };

  //honors and awards
  const [isHonorsEdit, setIsHonorsEdit] = useState(-1);
  const { industryData } = useSelector((state) => state.master);
  const [honorsData, sethonorsData] = useState({
    name: "",
    industry: "",
    year: "",
  });

  useEffect(() => {
    dispatch(getIndustryData());
  }, []);

  const handleHonorsEdit = (id) => {
    setIsHonorsEdit(id);
    const data = user?.honors_and_awards?.find((ele, i) => i == id);
    sethonorsData({
      name: data?.name,
      industry: data?.Industry ? data?.Industry[0]?.id : data?.industry,
      year: data?.year,
    });
  };
  const handleHonorsDelete = (id) => {
    dispatch(deleteHonors(id, editCV));
  };
  const handleHonorsChange = (e) => {
    sethonorsData({ ...honorsData, [e.target.name]: e.target.value });
  };

  const handleHonorsSubmit = () => {
    if (checkHonourValidation("all")) {
      dispatch(initPageLoad());
      if (isHonorsEdit > -1)
        dispatch(editHonors(honorsData, isHonorsEdit, editCV));
      else dispatch(addHonors(honorsData, editCV));

      sethonorsData({
        name: "",
        industry: "",
        year: "",
      });
      setIsHonorsEdit(-1);

      dispatch(stopPageLoad());
    }
  };

  const [honourError, setHonourError] = useState({
    name: "",
    industry: "",
    year: "",
  });

  const checkHonourValidation = (type) => {
    let formIsValid = true;

    if (type === "name" || type === "all") {
      if (!honorsData?.name?.trim()) {
        setHonourError({ ...honourError, name: "Name is mandatory." });
        formIsValid = false;
        return;
      } else {
        setHonourError({ ...honourError, name: "" });
      }
    }
    if (type === "industry" || type === "all") {
      if (!honorsData?.industry) {
        setHonourError({ ...honourError, industry: "industry is mandatory." });
        formIsValid = false;
        return;
      } else {
        setHonourError({ ...honourError, industry: "" });
      }
    }

    if (type === "year" || type === "all") {
      if (!honorsData?.year) {
        setHonourError({ ...honourError, year: "Year is mandatory." });
        formIsValid = false;
        return;
      } else {
        setHonourError({ ...honourError, year: "" });
      }
    }

    return formIsValid;
  };

  //language
  const [isLanEdit, setIsLanEdit] = useState(-1);
  const [proficiencyList, setProficiencyList] = useState([]);
  const [languageList, setLanguageList] = useState([]);

  const [lanData, setLanData] = useState({
    name: "",
    proficiency: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          "api/v1/language-proficiency",
          null,
          null
        );
        setProficiencyList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/locale", null, null);
        setLanguageList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleLanChange = (e) => {
    setLanData({ ...lanData, [e.target.name]: e.target.value });
  };

  const handleLanguageSubmit = () => {
    if (checkLanValidation("all")) {
      dispatch(initPageLoad());
      if (isLanEdit > -1) dispatch(editLanguage(lanData, isLanEdit, editCV));
      else dispatch(addLanguage(lanData, editCV));

      setLanData({
        name: "",
        proficiency: "",
      });
      setIsLanEdit(-1);

      dispatch(stopPageLoad());
    }
  };

  const handleLanEdit = (id) => {
    setIsLanEdit(id);
    const data = user?.languages?.find((ele, i) => i == id);
    setLanData({
      name: data?.name,
      proficiency: data?.proficiency,
    });
  };
  const handleLanDelete = (id) => {
    dispatch(deleteLanguage(id, editCV));
  };

  const [lanError, setLanError] = useState({
    name: "",
    proficiency: "",
  });

  const checkLanValidation = (type) => {
    let formIsValid = true;

    if (type === "name" || type === "all") {
      if (!lanData?.name) {
        setLanError({ ...lanError, name: "Name is mandatory." });
        formIsValid = false;
        return;
      } else {
        setLanError({ ...lanError, name: "" });
      }
    }
    if (type === "proficiency" || type === "all") {
      if (!lanData?.proficiency) {
        setLanError({ ...lanError, proficiency: "Proficiency is mandatory." });
        formIsValid = false;
        return;
      } else {
        setLanError({ ...lanError, proficiency: "" });
      }
    }

    return formIsValid;
  };
  const [confHonDel, setConfHonDel] = useState(false);
  const [confLanDel, setConfLanDel] = useState(false);

  const [honDelId, setHonDelId] = useState();
  const [lanDelId, setLanDelId] = useState();

  return (
    <div>
      {(isPageLoading || loader) && <AppLoader />}
      <div className="edit-resume ">
        <div className="container">
          <div className="row mt-30">
            <div className="col-lg-2 "></div>
            <div className="col-lg-6">
              <form>
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="mb-3 skill">
                      <label className="form-label">Skills</label>
                      <input
                        type="text"
                        className="form-control"
                        name="skills"
                        value={skills}
                        onKeyDown={(e) => handleSkill(e)}
                        maxLength={20}
                        onChange={(e) => {
                          setSkills(e.target.value);
                          if (e.target.value && e.target.value?.length < 3) {
                            setSkillError(
                              "Please enter at least three characters."
                            );
                          } else {
                            setSkillError("");
                          }
                        }}
                      />
                      {skillError && (
                        <p className="text-danger">{skillError}</p>
                      )}
                      <span>
                        <ul>
                          {user?.exps_and_skills?.map((ele, i) => {
                            return (
                              <>
                                <li>
                                  {ele?.skills ? ele?.skills : ele}{" "}
                                  <span
                                    onClick={() => deleteSkill(i)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    X
                                  </span>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </span>
                      <div className="color mt-3">
                        <div className="bg">
                          <div className="fill">Fill</div>
                          <div className="picker">
                            {/* <div className="box"></div>D9D9D9 */}
                            <div className="">
                              <input
                                type="color"
                                className="box"
                                onChange={(e) =>
                                  dispatch(
                                    editBasicInfo(
                                      { cv_skillColor: e.target.value },
                                      editCV
                                    )
                                  )
                                }
                                value={user?.cv_skillColor}
                              />{" "}
                              {user?.cv_skillColor}
                            </div>
                          </div>
                          <div className="gr">100%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3 skill">
                      <label className="form-label">Interests</label>
                      <input
                        type="text"
                        className="form-control"
                        name="interest"
                        value={interest}
                        onKeyDown={(e) => handleInterest(e)}
                        maxLength={20}
                        onChange={(e) => {
                          setInterest(e.target.value);
                          if (e.target.value && e.target.value?.length < 3) {
                            setInterestError(
                              "Please enter at least three characters."
                            );
                          } else {
                            setInterestError("");
                          }
                        }}
                      />
                      {interestError && (
                        <p className="text-danger">{skillError}</p>
                      )}
                      <span>
                        <ul>
                          {user?.interests?.map((ele, i) => {
                            return (
                              <>
                                <li>
                                  {ele}{" "}
                                  <span
                                    onClick={() => deleteInterest(i)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    X
                                  </span>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </span>
                      <div className="color mt-3">
                        <div className="bg">
                          <div className="fill">Fill</div>
                          <div className="picker">
                            {/* <div className="box"></div>D9D9D9 */}
                            <div className="">
                              <input
                                type="color"
                                className="box"
                                onChange={(e) =>
                                  dispatch(
                                    editBasicInfo(
                                      { cv_interestColor: e.target.value },
                                      editCV
                                    )
                                  )
                                }
                                value={user?.cv_interestColor}
                              />{" "}
                              {user?.cv_interestColor}
                            </div>
                          </div>
                          <div className="gr">100%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3 skill">
                      <label for="exampleInputEmail1" className="form-label">
                        Honors & Awards
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onBlur={() => checkHonourValidation("name")}
                        name="name"
                        value={honorsData?.name}
                        onChange={(e) => handleHonorsChange(e)}
                        maxLength={100}
                      />
                      {honourError?.name && (
                        <p className="text-danger">{honourError?.name}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Industry
                      </label>
                      <Input
                        type="select"
                        className="form-control"
                        name="industry"
                        onChange={(e) => handleHonorsChange(e)}
                        value={honorsData?.industry}
                        onBlur={() => checkHonourValidation("industry")}
                      >
                        <option value="">Select</option>
                        {industryData?.map((item, index) => {
                          return (
                            <option key={index} value={item?.name}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </Input>
                      {honourError?.industry && (
                        <p className="text-danger">{honourError?.industry}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Year
                      </label>
                      <YearPickerCustom
                        value={
                          honorsData?.year
                            ? new Date(new Date().setFullYear(honorsData?.year))
                            : ""
                        }
                        onChange={(date) => {
                          date ? (
                            sethonorsData({
                              ...honorsData,
                              year: moment(date).format("YYYY"),
                            })
                          ) : (
                            <></>
                          );
                        }}
                        placeholder="Pick Year"
                        onBlur={() => checkHonourValidation("year")}
                        max={new Date()}
                      />
                      {honourError?.year && (
                        <p className="text-danger">{honourError?.year}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="">
                      <div
                        style={{ cursor: "pointer" }}
                        className="text-blue  bg-transparent"
                        onClick={handleHonorsSubmit}
                      >
                        <strong>{isHonorsEdit > -1 ? "Update" : "Add"}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  {user?.honors_and_awards?.map((ele, i) => {
                    return (
                      <>
                        <div className="col-lg-8 col-12 mt-2">
                          <div className="">
                            <span className="tick">
                              <img src={require("../../assets/img/tick.png")} />
                            </span>

                            <span className="ui ms-3">
                              {ele?.name?.length > 10
                                ? ele?.name.slice(0, 5) + "..."
                                : ele?.name}{" "}
                              {ele?.year}{" "}
                              {ele?.Industry?.length > 0
                                ? ele?.Industry[0]?.name?.length > 10
                                  ? ele?.Industry[0]?.name.slice(0, 5) + "..."
                                  : ele?.Industry[0]?.name
                                : ele?.industry}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="float-end">
                            <div className="d-flex gap-3">
                              <div
                                className="edit"
                                onClick={() => handleHonorsEdit(i)}
                              ></div>
                              <div
                                className="delete"
                                onClick={() => {
                                  setHonDelId(i);
                                  setConfHonDel(true);
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                    <div className="mb-3 skill">
                      <label for="exampleInputEmail1" className="form-label">
                        Language
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      {/* <label for="exampleInputEmail1" className="form-label">
                        Industry
                      </label> */}
                      <Input
                        type="select"
                        className="form-select form-select-sm"
                        value={lanData?.name}
                        onBlur={() => checkLanValidation("name")}
                        name="name"
                        onChange={(e) => handleLanChange(e)}
                      >
                        <option value="">Select</option>
                        {languageList.map((item, index) => {
                          return (
                            <option key={index} value={item?.name}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </Input>
                      {lanError?.name && (
                        <p className="text-danger">{lanError?.name}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <Input
                        type="select"
                        className="form-select form-select-sm"
                        value={lanData?.proficiency}
                        onBlur={() => checkLanValidation("proficiency")}
                        name="proficiency"
                        onChange={(e) => handleLanChange(e)}
                      >
                        <option value="">Select</option>
                        {proficiencyList?.map((item, index) => {
                          return (
                            <option key={index} value={item?.name}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </Input>
                      {lanError?.proficiency && (
                        <p className="text-danger">{lanError?.proficiency}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="">
                      <div
                        style={{ cursor: "pointer" }}
                        className="text-blue bg-transparent"
                        onClick={handleLanguageSubmit}
                      >
                        <strong>{isLanEdit > -1 ? "Update" : "Add"}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  {user?.languages?.map((ele, i) => {
                    return (
                      <>
                        <div className="col-lg-8 col-12 mt-2">
                          <div className="">
                            <span className="tick">
                              <img src={require("../../assets/img/tick.png")} />
                            </span>
                            <span className="ui ms-3">
                              {ele?.name}, {ele?.proficiency}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="float-end">
                            <div className="d-flex gap-3">
                              <div
                                className="edit"
                                onClick={() => handleLanEdit(i)}
                              ></div>
                              <div
                                className="delete"
                                onClick={() => {
                                  setLanDelId(i);
                                  setConfLanDel(true);
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="text-center mx-4 mt-5">
                  <div className="" style={{ marginLeft: "25%" }}>
                    <div
                      className=" btn btn-light text-blue me-3"
                      onClick={() => setPageNo(3)}
                    >
                      Back
                    </div>
                    <div
                      className=" btn btn-blue"
                      onClick={() => {
                        setPageNo(1);
                        toggle();
                      }}
                    >
                      Save
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              <div className="resume ">
                <div className="container">
                  <div className="bg-white main-box">
                    <div className="row header ">
                      <div className="col-lg-12 ">
                        {/* {editCV == "classic" ? (
                          <ClassicTemplate
                            userData={user}
                            // color={color?.classic}
                            // skillBackground={skillBackground?.classic}
                            // interestBackground={interestBackground?.classic}
                          />
                        ) : editCV == "functional" ? (
                          <FunctionalTemplate
                            userData={user}
                            // color={color?.functional}
                            // skillBackground={skillBackground?.functional}
                            // interestBackground={interestBackground?.functional}
                          />
                        ) : editCV == "combined" ? (
                          <CombinedTemplate
                            userData={user}
                            // color={color?.combined}
                            // skillBackground={skillBackground?.combined}
                            // interestBackground={interestBackground?.combined}
                          />
                        ) : (
                          <ModernTemplate
                            userData={user}
                            // color={color?.modern}
                            // skillBackground={skillBackground?.modern}
                            // interestBackground={interestBackground?.modern}
                          />
                        )} */}

                        <div>
                          {isLoading ? (
                            <div className="position-relative">
                              <AppLoader />
                            </div>
                          ) : (
                            <div className="" style={{ position: "relative" }}>
                              <ThumbnailResumePreview url={cvTypes[editCV]} />
                              <button
                                style={{
                                  position: "absolute",
                                  zIndex: 999999,
                                  right: "10px",

                                  bottom: "10px",
                                  background: "#0432A3",
                                  color: "#fff",
                                  borderRadius: "20px",
                                  padding: "5px",
                                  fontSize: "12px",
                                }}
                                onClick={() => {
                                  setPreviewCV(editCV);
                                  setPreviewResumeModal(true);
                                }}
                              >
                                Preview
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="bullets mt-30">
                <div className="dot active"></div>
                <div className="dot"></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {confHonDel && (
        <DeleteModal
          text="Are you sure you want to delete honours"
          title="Delete Honours & Awards"
          modal={confHonDel}
          toggle={() => setConfHonDel(!confHonDel)}
          deleteConfirm={() => handleHonorsDelete(honDelId)}
        />
      )}
      {confLanDel && (
        <DeleteModal
          text="Are you sure you want to delete language"
          title="Delete Language"
          modal={confLanDel}
          toggle={() => setConfLanDel(!confLanDel)}
          deleteConfirm={() => handleLanDelete(lanDelId)}
        />
      )}
    </div>
  );
};
