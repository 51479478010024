import React, { useEffect, useRef, useState } from "react";
import { Dropdown, FormGroup, Input, Label } from "reactstrap";
import JoinUsSection from "../../../core/components/Sections/JoinUsSection/JoinUsSection";
import { useDispatch, useSelector } from "react-redux";
import {
  initPageLoad,
  stopPageLoad,
} from "../../../store/loader/loaderActions";
import { toast } from "react-toastify";
import axios from "axios";
import { call } from "../../../api/apiCall";
import { addeducationdetails } from "../../../store/profile/profileActions";
import DatePickerCustom from "../../../core/components/DatePicker/DatePickerReact";
import moment from "moment";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import AddMediaExper from "../../profile-pages/profile/components/AddMediaExper";
import deleteIcon from "../../../assets/img/deleteIcon.svg";
import DeleteModal from "../../../core/components/modal/DeleteModal";
const AddEducation = (props) => {
  const token = localStorage.getItem("accessTpToken");
  const { talentPoolData } = useSelector((state) => state.portal);
  const { isPageLoading } = useSelector((state) => state.loader);
  const { activePage, setActivePage, activeTab, setActiveTab } = props;
  const { profileData } = useSelector((state) => state.profile);
  const [expData, setExpData] = useState({
    school_name: "",
    degree: "",
    field_of_study: "",
    start_date: "",
    end_date: "",
    grade: "",
    description: "",
    medias: [],
    is_highest: false,
  });

  const [degreeList, setDegreeList] = useState("");
  const [studyFieldList, setStudyFieldList] = useState("");
  const [addMediaModal, setAddMediaModal] = useState(false);
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [mediaDescription, setMediaDescription] = useState("");
  const [selectedMediaId, setSelectedMediaId] = useState("");

  const [showLabel, setShowLabel] = useState("");
  const [showLabelFos, setShowLabelFos] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const dispatch = useDispatch();
  const imgRef = useRef();
  const handleChange = async (e, type) => {
    var fileExtension = e.target.value.split(".").pop();
    var validFile = true;
    let fileValidation = /^(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;
    if (e.target.id === "chosefile1") {
      if (fileValidation.test(fileExtension) == false) {
        validFile = false;
      }
    }
    if (validFile) {
      let tempimage = [];
      Array.from(e.target.files).map((file) => {
        tempimage.push({
          type,
          file,
        });
      });
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };

        for (var i = 0; i < tempimage.length; i++) {
          formData.append("image", tempimage[i].file);
        }
        dispatch(initPageLoad());
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          dispatch(stopPageLoad());
          let { filesArray, urlsArray } = res.data;
          setFileName(filesArray[0].originalname);
          setImage(urlsArray[0]);
        });
        //  e.target.files = ''
      } catch (err) {
        if (err.response.data.message === "File too large") {
          toast.warn("File is too large, please upload another file", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          dispatch(stopPageLoad());
        }
        console.log(err);
      }
    } else {
      // alert("Please choose valid file type");
      toast.error(
        "Please upload only file types jpg/jpeg/png/gif/JPG/JPEG/PNG/GIF"
      );
      return;
    }
  };

  const handleAddMedia = () => {
    // setExpData({ ...expData, title: e.target.value })
    setExpData({
      ...expData,
      medias: [
        ...expData?.medias,
        {
          title: fileName,
          description: mediaDescription,
          image: image,
          type: "education",
        },
      ],
    });
    setFileName("");
    setMediaDescription("");
    setAddMediaModal(false);
  };
  useEffect(() => {
    if (image) setAddMediaModal(true);
  }, [image]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/degree", null, null);
        setDegreeList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/study-field", null, null);
        setStudyFieldList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
    setShowLabel(expData.degree);
    setShowLabelFos(expData.field_of_study);
  }, []);
  const [error, setError] = useState({
    school_name: "",
    degree: "",
    start_date: "",
    end_date: "",
    field_of_study: "",
    grade: "",
  });
  const checkValidation = (type) => {
    let formIsValid = true;
    if (type === "school_name" || type === "all") {
      if (!expData?.school_name?.trim()) {
        setError({ ...error, school_name: "School name is mandatory." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, school_name: "" });
      }
    }
    if (type === "degree" || type === "all") {
      if (!expData?.degree) {
        setError({ ...error, degree: "Degree is mandatory." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, degree: "" });
      }
    }

    if (type === "field_of_study" || type === "all") {
      if (!expData?.field_of_study) {
        setError({ ...error, field_of_study: "Field is mandatory." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, field_of_study: "" });
      }
    }

    if (type === "start_date" || type === "all") {
      if (!expData?.start_date) {
        setError({ ...error, start_date: "Start date is mandatory" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, start_date: "" });
      }
    }

    if (type === "end_date" || type === "all") {
      if (!expData?.end_date) {
        setError({ ...error, end_date: "End date is mandatory" });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, end_date: "" });
      }
    }
    if (type === "grade" || type === "all") {
      if (!expData?.grade?.trim()) {
        setError({ ...error, grade: "Grade is mandatory." });
        formIsValid = false;
        return;
      } else {
        setError({ ...error, grade: "" });
      }
    }

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkValidation("all")) {
      dispatch(initPageLoad());
      let newData = { ...expData, screening_status: "pending" };
      Object.keys(newData).forEach((key) => {
        if (!newData[key]) delete newData[key];
      });
      let expDataformat = {
        educations: [newData],
      };
      dispatch(addeducationdetails(expDataformat, token))
        .then((res) => {
          if (res?.success) {
            setActivePage("intro");
            toast.success("Education added successfully", {
              position: "bottom-left",
            });
          }
          dispatch(stopPageLoad());
        })
        .catch((e) => {
          console.log(e);
          dispatch(stopPageLoad());
        });
    }
  };
  return (
    <>
      {isPageLoading && <AppLoader />}
      <div className="add-education-section">
        <div className="edit-form">
          <h3>Add Education</h3>
          <form>
            <div className="title mb-3 mt-3">
              <label>School</label>
              <Input
                type="text"
                placeholder="Enter title"
                maxLength="80"
                value={expData.school_name}
                onBlur={() => checkValidation("school_name")}
                onChange={(e) =>
                  setExpData({ ...expData, school_name: e.target.value })
                }
              />
              {error?.school_name && (
                <p className="label-input100 text-danger">
                  {error?.school_name}
                </p>
              )}
            </div>
            <div className="exper mb-3 mt-3">
              <FormGroup>
                <Label for="employmentropdown">Degree</Label>
                <Input
                  value={expData.degree}
                  id="exampleSelect"
                  name="select"
                  type="select"
                  onChange={(e) =>
                    setExpData({
                      ...expData,
                      degree: e.target.value,
                    })
                  }
                  onBlur={() => checkValidation("degree")}
                >
                  <option value="">Select degree</option>
                  {degreeList?.data?.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item?.fullName}({item?.name})
                      </option>
                    );
                  })}
                </Input>
                {error?.degree && (
                  <p className="label-input100 text-danger">{error?.degree}</p>
                )}
              </FormGroup>
            </div>
            <div className="exper mb-3 mt-3">
              <FormGroup>
                <Label for="employmentropdown">Field of study</Label>
                <Input
                  value={expData.field_of_study}
                  style={{ color: "#676A79" }}
                  id="fieldStudySelect"
                  name="select"
                  type="select"
                  onChange={(e) =>
                    setExpData({
                      ...expData,
                      field_of_study: e.target.value,
                    })
                  }
                  onBlur={() => checkValidation("field_of_study")}
                >
                  <option value="">Select field of study</option>
                  {studyFieldList?.data?.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
                {error?.field_of_study && (
                  <p className="label-input100 text-danger">
                    {error?.field_of_study}
                  </p>
                )}
              </FormGroup>
            </div>
            <div className="double-input">
              <div className="mb-3">
                <label>Start date</label>
                <DatePickerCustom
                  value={expData.start_date ? new Date(expData.start_date) : ""}
                  onChange={(date) => {
                    date
                      ? setExpData({
                          ...expData,
                          start_date: moment(date).format("YYYY-MM-DD"),
                          end_date: "",
                        })
                      : setExpData({
                          ...expData,
                          start_date: "",
                          end_date: "",
                        });
                  }}
                  placeholder="Pick"
                  onBlur={(e) => checkValidation("start_date")}
                />
                {error?.start_date && (
                  <p className="label-input100 text-danger">
                    {error?.start_date}
                  </p>
                )}
              </div>
              <div className="mb-3">
                <label>End date (or expected)</label>
                <DatePickerCustom
                  value={expData.end_date ? new Date(expData.end_date) : ""}
                  onChange={(date) => {
                    date
                      ? setExpData({
                          ...expData,
                          end_date: moment(date).format("YYYY-MM-DD"),
                        })
                      : setExpData({ ...expData, end_date: "" });
                  }}
                  placeholder="Pick"
                  onBlur={(e) => checkValidation("end_date")}
                  disabled={expData.start_date === "" ? true : false}
                  min={expData.start_date ? new Date(expData.start_date) : ""}
                />
                {error?.end_date && (
                  <p className="label-input100 text-danger">
                    {error?.end_date}
                  </p>
                )}
              </div>
            </div>
            <div className="title mb-3 mt-3">
              <label>Grade</label>
              <Input
                value={expData.grade}
                onChange={(e) =>
                  setExpData({ ...expData, grade: e.target.value })
                }
                onBlur={() => checkValidation("grade")}
              />
              {error?.grade && (
                <p className="label-input100 text-danger">{error?.grade}</p>
              )}
            </div>
            <div className="contact-info mb-3 mt-4">
              <h6>Media</h6>
              <p>
                Incorporate relevant media to support your education, if
                available.
              </p>
              <span
                style={{ color: `${talentPoolData?.theme}` }}
                className="addButton orange-clr"
                onClick={() => {
                  try {
                    document.getElementById("chosefile1").click();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                + Add media
              </span>
            </div>
            {expData?.medias?.map((item, index) => {
              return (
                <div
                  className="show-media d-flex justify-content-between align-items-center m-3"
                  key={index}
                >
                  <div className="show-media d-flex m-3">
                    <div className="show-media-image">
                      <img
                        alt="media"
                        src={item?.image}
                        className="main-image"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="mx-3">
                      <p className="card-control-text-oneline">{item?.title}</p>
                      <p className="card-control-text-oneline">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                  <img
                    src={deleteIcon}
                    height={30}
                    width={30}
                    onClick={() => {
                      setDeleteModal(true);
                      setDeleteIndex(index);
                    }}
                  />
                </div>
              );
            })}
          </form>
          <input
            type="file"
            ref={imgRef}
            accept="image/png, image/gif, image/jpeg"
            id="chosefile1"
            onChange={(e) => handleChange(e, "image")}
            onClick={(e) => {
              e.target.value = "";
              setImage("");
              setFileName("");
            }}
          />
        </div>

        {!profileData?.np && <JoinUsSection />}
        <button
          className="close-btn"
          onClick={() => setActivePage("intro")}
          style={{
            border: `1px solid ${talentPoolData?.theme}`,
            color: `${talentPoolData?.theme}`,
          }}
        >
          Close
        </button>
        <button
          className="save-btn"
          onClick={(e) => handleSubmit(e)}
          style={{ background: `${talentPoolData?.theme}` }}
        >
          Save
        </button>
      </div>
      {addMediaModal && (
        <AddMediaExper
          modal={addMediaModal}
          toggle={() => setAddMediaModal(!addMediaModal)}
          image={image}
          setImage={setImage}
          fileName={fileName}
          setFileName={setFileName}
          mediaDescription={mediaDescription}
          setMediaDescription={setMediaDescription}
          add={() => handleAddMedia()}
          expData={expData}
          setExpData={setExpData}
          selectedMediaId={selectedMediaId}
        />
      )}

      {deleteModal && (
        <DeleteModal
          modal={DeleteModal}
          toggle={() => setDeleteModal(!deleteModal)}
          title="Delete Confirmation"
          deleteConfirm={() => {
            const media = expData?.medias?.filter(
              (ele, i) => i !== deleteIndex
            );
            setExpData({ ...expData, medias: media });
            setDeleteIndex();
          }}
          text="Are you sure you want to delete this?"
        />
      )}
    </>
  );
};

export default AddEducation;
