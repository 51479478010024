import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import FormInput from "../../../core/components/Inputs/FormInput";

const HibernateAccountModal = (props) => {
  const { modal, toggle, title, data } = props;
  const accValue = data?.settings[0]?.value;
  const { user } = useSelector((state) => state.auth);
  const [responseMsg, setResponseMsg] = useState("");
  const [value, setValue] = useState(accValue);
  const [passErrorMessage, setPassErrorMessage] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);

  const dispatch = useDispatch();

  const updateValue = (event) => {
    setBtnStatus(true);
    setValue(event.target.value);
    setPassErrorMessage("");
  };
  const updateMode = async () => {
    try {
      let token = localStorage.getItem("accessToken");

      let updateData = {
        setting: [
          {
            setting_master_id: 24,
            value: value,
          },
        ],
      };
      dispatch(updateSettingDetails(updateData, token)).then((res) => {
        dispatch(getUserSetting(0, token));
        setResponseMsg(res.message);
        setTimeout(() => {
          setResponseMsg(false);
          toggle();
        }, 2000);
        alert("This feature will be implemented in the future milestone");
      });
    } catch (error) {
      console.log(error);
    }
  };
  const validation = () => {
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!value) {
      setPassErrorMessage("Please Enter Your Password");
      return;
    }
    if (!passwordValid.test(value)) {
      setPassErrorMessage(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
      );
      return;
    }
    updateMode();
  };

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">
              {title}
            </ModalHeader>
            <ModalBody>
              <div className="box">
                <p>Your account will be deactivated!</p>
                <div className="d-flex gap-4">
                  <div className="profile-img">
                    <img
                      src="https://img.freepik.com/free-photo/pleasant-looking-serious-man-stands-profile-has-confident-expression-wears-casual-white-t-shirt_273609-16959.jpg"
                      className="image-fit"
                      style={{ width: "125px" }}
                      alt=""
                    />
                  </div>
                  <div className="pro">
                    <div className="text mb-30">
                      <h6>Gayathri Suresh</h6>
                      <p>Ui/Ux Designer</p>
                    </div>
                    <p>Please insert your password. </p>
                    <div className="position-relative">
                      {/* <input
                        type="text"
                        className="form-control mb-30 mt-20"
                        id="exampleInputEmail"
                      /> */}
                      <FormInput
                        type="password"
                        onChange={(e) => updateValue(e)}
                        placeholder="Password"
                        maxLength="24"
                        error={passErrorMessage}
                        containerClassName={
                          passErrorMessage ? "error-form" : ""
                        }
                      />
                    </div>
                    <div
                      className={
                        btnStatus ? "btn btn-blue" : "btn btn-blue disabledItem"
                      }
                      onClick={() => validation()}
                      disabled={btnStatus}
                    >
                      Hibernate Account
                    </div>
                  </div>
                </div>
                {responseMsg && (
                  <label className="label-input100 text-success">
                    {responseMsg}
                  </label>
                )}
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HibernateAccountModal;
