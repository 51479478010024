import React, { useCallback, useEffect, useRef, useState } from "react";
import Navbar from "../../../../pages/navbar/navbar";
import "./GiveSmartInterview.scss";
import crossiIcon from "../../../../assets/img/Closeicon.svg";
import Webcam from "react-webcam";
import { useDispatch, useSelector } from "react-redux";
import ArrowLeftIcon from "../../../../assets/img/Arrow-left.svg";
import ArrowRightIcon from "../../../../assets/img/Arrow-right.svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { jobApi } from "../../../../api/jobs";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";
import { initLoad, stopLoad } from "../../../../store/loader/loaderActions";
import ConfirmationModal from "../../../../core/components/modal/ConfirmationModal";

const GiveSmartInterview = () => {
  const { isLoading } = useSelector((state) => state.loader);
  const { state } = useLocation();
  const { questionaires, shortlist_id } = state;
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [interviewAnswer, setInterviewAnswer] = useState([]);
  const [interviewCompleted, setInterviewCompleted] = useState(false);

  const [discardModal, setDiscardModal] = useState(false);
  const [skipModal, setSkipModal] = useState(false);
  const [webcamAlertModal, setWebcamAlertModal] = useState(false);

  const dispatch = useDispatch();
  const timeLimit = 30;

  const [timer, setTimer] = useState(0); // 3 minutes in seconds
  const [videoTimestamp, setVideoTimestamp] = useState(0);

  let interval;

  useEffect(() => {
    setInterviewAnswer(
      questionaires?.map((question) => ({
        question,
        // shortlist_id,
      })) || []
    );
    handleNextQuestion();
  }, []);

  useEffect(() => {
    handleDownload();
  }, [recordedChunks]);

  const handleNextQuestion = () => {
    dispatch(initLoad());
    setTimeout(() => {
      if (webcamRef.current?.stream) {
        if (currentQuestion === null) {
          setCurrentQuestion(0);
          // setCapturing(true);
          setTimer(timeLimit);
          // handleStartCaptureClick();
        } else if (currentQuestion + 1 < interviewAnswer.length) {
          setCurrentQuestion((value) => value + 1);
          // setCapturing(true);
          setTimer(timeLimit);
          // handleStartCaptureClick();
        } else {
          setInterviewCompleted(true);
          const tracks = webcamRef.current?.stream.getTracks();
          tracks?.forEach((track) => track.stop());
          console.log("Payload Ready", interviewAnswer);
        }
      } else {
        setWebcamAlertModal(true);
        // alert("Please connect your webcam in the system");
      }
      dispatch(stopLoad());
    }, 1000);
  };

  const webConnect = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        webcamRef.current.stream = stream;
        webcamRef.current.video.srcObject = stream;
        webcamRef.current.video.play();
      })
      .catch((err) => {
        console.error("Error accessing webcam:", err);
      });
    handleNextQuestion();
  };

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    console.log("Start recording...");
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );

    mediaRecorderRef.current.start();

    interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) return prevTimer - 1;
        else {
          handleStopCaptureClick();
          return 0;
        }
      });
      setVideoTimestamp((prevTimer) => prevTimer + 1);
    }, 1000);
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    console.log("stop recording...");
    dispatch(initLoad());
    mediaRecorderRef.current.stop();
    setCapturing(false);
    clearInterval(interval);
  }, [mediaRecorderRef, setCapturing]);

  const handleDownload = useCallback(async () => {
    if (recordedChunks?.length) {
      console.log("Upload Recording...");
      const blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      const newVideo = new File([blob], "newRecordedVideo.mp4", {
        type: "video/mp4",
      });
      try {
        const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
        const formData = new FormData();
        const headers = {
          "Accept-Language": "en",
          "content-type": "multipart/form-data",
        };

        formData.append("image", newVideo);
        await axios.post(UPLOAD_URL, formData, { headers }).then((res) => {
          if (res?.data?.urlsArray[0]) {
            let answers = interviewAnswer.slice();
            Object.assign(answers[currentQuestion], {
              answer_url: res.data?.urlsArray[0],
              timestamp: videoTimestamp * 1000,
              // answer_url: currentQuestion,
            });
            // delete answers[currentQuestion].shortlist_id;
            setInterviewAnswer(answers);
            handleNextQuestion();
          }
        });
      } catch (err) {
        console.log(err);
      }
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement("a");
      // document.body.appendChild(a);
      // a.style = "display: none";
      // a.href = url;
      // a.download = "react-webcam-stream-capture.mp4";
      // a.click();
      // window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  const videoConstraints = {
    width: 1000,
    height: 500,
    facingMode: "user",
  };

  const handleSubmit = async () => {
    const data = {
      interview_answers: interviewAnswer?.filter((obj) => obj?.answer_url),
      shortlist_id,
    };
    mediaRecorderRef.current.stop();
    try {
      const res = await jobApi.createGiveInterview(data);
      navigate("/myjobs", { subTab: "Smart Interview Sent" });
    } catch (error) {
      alert(error?.response?.data?.message || error.message);
      // window.location.reload();
    }
  };

  const handleSkip = async () => {
    // setTimer(0);
    // handleStopCaptureClick();
    // handleDownload();
    handleNextQuestion();
  };
  return (
    <>
      <div>
        <Navbar />
        {isLoading && <AppLoader />}
        <div className="give_smart_interview">
          <div className="bg">
            <div className="container">
              <div className="bg-white p-3 interview_container">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="text-dark">
                    Question : {currentQuestion + 1}/{interviewAnswer?.length}
                  </h4>
                  <img
                    style={{ cursor: "pointer" }}
                    src={crossiIcon}
                    onClick={() => setDiscardModal(!discardModal)}
                  />
                </div>

                <div className="question mt-2 row d-flex justify-content-between align-items-center">
                  <p className="text-secondary mt-2 col-9">
                    {interviewAnswer?.length > currentQuestion
                      ? interviewAnswer[currentQuestion]?.question
                      : "Lorem ipsum dolor sit am id, consectetur adipiscing elit in diam nonum \nvul tempus et justo euismod. Null a ante"}
                  </p>
                  {!interviewCompleted && (
                    <div
                      className={`timer col-2 ${timer < 10 ? "red" : "green"}`}
                    >
                      <p>{`${Math.floor(timer / 60)
                        .toString()
                        .padStart(2, "0")}:${(timer % 60)
                        .toString()
                        .padStart(2, "0")}`}</p>
                    </div>
                  )}
                </div>

                <div className="w-100 mt-3 bg-black cam-container">
                  {/* <div className="position-relative"> */}
                  <Webcam
                    height={500}
                    width="100%"
                    audio={true}
                    disablePictureInPicture={true}
                    onUserMedia={(r) => console.log("UserMedia: ", r)}
                    onUserMediaError={(e) =>
                      console.log("UserMedia Error: ", e)
                    }
                    // controls={true}
                    // mirrored={true}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                  />
                  <div className="timer">
                    <p>
                      <span>
                        {`00:${Math.floor(videoTimestamp / 60)
                          .toString()
                          .padStart(2, "0")}:${(videoTimestamp % 60)
                          .toString()
                          .padStart(2, "0")}`}
                      </span>
                    </p>
                  </div>
                  {/* </div> */}

                  {/* {capturing ? (
                    <div
                      className="button-class"
                      onClick={handleStopCaptureClick}
                    >
                      Stop Capture
                    </div>
                  ) : (
                    <div
                      className="button-class"
                      onClick={handleStartCaptureClick}
                    >
                      Start Capture
                    </div>
                  )} */}
                  {/* {recordedChunks.length > 0 && (
                    <div className="button-class" onClick={handleDownload}>
                      Upload Video
                    </div>
                  )} */}
                </div>
                {interviewCompleted && (
                  <div className="text-success text-center my-3 fw-bold">
                    {" "}
                    Smart Interview has been completed, Please submit your
                    interview.{" "}
                  </div>
                )}
                <div className="mt-4 d-flex justify-content-between">
                  <div></div>
                  <div className="">
                    {interviewCompleted && (
                      <button
                        className="btn text-white sub_btn"
                        onClick={handleSubmit}
                      >
                        Submit Interview
                      </button>
                    )}
                    {!interviewCompleted &&
                      (!capturing ? (
                        <button
                          className="btn text-white sub_btn"
                          onClick={handleStartCaptureClick}
                        >
                          Answer
                        </button>
                      ) : (
                        <button
                          className="btn text-white sub_btn"
                          onClick={handleStopCaptureClick}
                        >
                          Submit
                        </button>
                      ))}
                  </div>
                  {!interviewCompleted && !capturing ? (
                    <img
                      src={ArrowRightIcon}
                      onClick={() => setSkipModal(true)}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {discardModal && (
        <ConfirmationModal
          modal={discardModal}
          toggle={() => setDiscardModal(!discardModal)}
          title="Confirmation"
          Confirm={() => {
            navigate("/recruiter/smart-interview", {
              state: { questionaires, shortlist_id: state?.id },
            });
          }}
          text="Are you want to sure to discard interview?"
        />
      )}
      {skipModal && (
        <ConfirmationModal
          modal={skipModal}
          toggle={() => setSkipModal(!skipModal)}
          title="Confirmation"
          Confirm={() => handleSkip()}
          text="Are you want to sure to skip this question?"
        />
      )}
      {webcamAlertModal && (
        <ConfirmationModal
          modal={webcamAlertModal}
          toggle={() => setWebcamAlertModal(!webcamAlertModal)}
          title="WebCam Error"
          ConfirmText="Connect Again"
          Confirm={() => {
            webConnect();
          }}
          RejectText="Cancel"
          Reject={() => {
            navigate("/recruiter/smart-interview", {
              state: { questionaires, shortlist_id: state?.id },
            });
          }}
          text="Please connect your webcam in the system"
        />
      )}
    </>
  );
};

export default GiveSmartInterview;
