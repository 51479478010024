import React, { useEffect, useState } from "react";
import { Dropdown, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import JoinUsSection from "../../../core/components/Sections/JoinUsSection/JoinUsSection";
import { useDispatch, useSelector } from "react-redux";
import { PhoneNumberUtil } from "google-libphonenumber";
import moment from "moment/moment";
import { call } from "../../../api/apiCall";
import { getLocationsData } from "../../../store/connections/connectionsActions";
import postalCodes from "postal-codes-js";
import MobileInput from "../../../core/components/Inputs/MobileInput";
import DatePickerCustom from "../../../core/components/DatePicker/DatePickerReact";
import Autocomplete from "react-autocomplete";
import { toast } from "react-toastify";
import {
  initPageLoad,
  stopPageLoad,
} from "../../../store/loader/loaderActions";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import { updateContactDetails } from "../../../store/profile/profileActions";

const EditContactInfo = (props) => {
  const { activePage, setActivePage, activeTab, setActiveTab, profileData } =
    props;

  const [websiteTouched, setWebsiteTouched] = useState(false);
  const { talentPoolData } = useSelector((state) => state.portal);
  const token = localStorage.getItem("accessTpToken");
  const { isPageLoading } = useSelector((state) => state.loader);
  const [countryList, setCountryList] = useState("");
  const [stateList, setStateList] = useState("");
  const [cityList, setCityList] = useState("");
  const [profileUrlError, setProfileUrlError] = useState("");
  const { isWebView } = useSelector((state) => state.auth);
  const [showLabel, setShowLabel] = useState("");
  const dispatch = useDispatch();
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [phoneCount, setPhoneCount] = useState("");
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [locationInput, setLocationInput] = useState(
    profileData ? profileData?.location : ""
  );
  const [locationList, setLocationList] = useState([]);
  const [countryCode, setCountryCode] = useState(
    profileData ? profileData?.country?.code : ""
  );
  const [errorPostCode, setErrorPostCode] = useState("");
  const [countryInput, setCountryInput] = useState(
    profileData ? profileData?.nationality?.name : ""
  );
  const [nationalityValid, setNationalityValid] = useState(true);
  const [isRegionValid, setIsRegionValid] = useState(true);
  const [contactData, setContactData] = useState({
    phone: profileData?.phone || "",
    dob: profileData?.dob
      ? moment(profileData?.dob).format("YYYY-MM-DD")
      : null,
    address: profileData?.address || "",
    nationality: profileData?.nationality?.name || "",
    country: profileData?.country || null,
    state: profileData?.state?.id || null,
    city: profileData?.city?.id || null,
    pin_code: profileData?.pin_code || "",
    location: profileData?.location || null,
    website: profileData?.website || "",
  });

  useEffect(() => {
    console.log(nationalityValid, "nationalityValid");
  }, [nationalityValid]);

  useEffect(() => {
    setContactData({
      phone: profileData?.phone || "",
      dob: profileData?.dob
        ? moment(profileData?.dob).format("YYYY-MM-DD")
        : null,
      address: profileData?.address || "",
      nationality: profileData?.nationality?.name || "",
      country: profileData?.country || null,
      state: profileData?.state?.id || null,
      city: profileData?.city?.id || null,
      pin_code: profileData?.pin_code || "",
      location: profileData?.location || null,
      website: profileData?.website || "",
    });
  }, [profileData]);

  useEffect(() => {
    if (locationInput) {
      dispatch(getLocationsData(locationInput))
        .then((res) => {
          if (res?.data) {
            setLocationList(res?.data);
          } else setLocationList([]);
        })
        .catch(() => {
          setLocationList([]);
        });
    }
  }, [locationInput]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/country", null, null);
        setCountryList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
    setShowLabel(contactData?.nationality?.name);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          `api/v1/state?country_id=${contactData.country?.id}`,
          null,
          null
        );
        setStateList(res.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [contactData.country]);

  useEffect(() => {
    if (contactData && contactData.state) {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/city?state_id=${contactData.state?.id}`,
            null,
            null
          );
          setCityList(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [contactData.state, contactData.country]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await call("get", `api/v1/nationality`, null, null);
  //       setNationalityList(res?.data?.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   })();
  // }, []);

  const phoneExist = (phoneNumber) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/auth/phone-exist/${phoneNumber}`,
            null,
            null,
            null
          );
          console.log(res);
          if (res?.data?.success) setErrorPhoneNumber("");
          else setErrorPhoneNumber("Phone already exist.");
        } catch (err) {
          console.log(err);
        }
      })();
    });
  };

  const checkSignUpValidation = (type) => {
    let formIsValid = true;

    if (
      (type === "all" && profileData?.phone !== contactData?.phone) ||
      (type === "phoneNumber" && profileData?.phone !== contactData?.phone)
    ) {
      if (contactData?.phone?.length === 0) {
        setErrorPhoneNumber("Please enter the phone number.");
        formIsValid = false;
      } else if (contactData?.phone?.length < 10) {
        setErrorPhoneNumber("Please enter the valid number.");
        formIsValid = false;
      } else if (!phoneCountValidation()) {
        setErrorPhoneNumber("Please enter the valid number.");
        formIsValid = false;
      } else {
        setErrorPhoneNumber("");
      }
    }

    if (type === "all") {
      if (postCodeValidator(contactData.pin_code, countryCode) === true) {
        setErrorPostCode("");
      } else {
        setErrorPostCode("Invalid Post Code.");
        formIsValid = false;
      }
    }

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkSignUpValidation("all")) {
      dispatch(initPageLoad());
      dispatch(updateContactDetails(contactData, token))
        .then((res) => {
          if (res?.success) {
            toast.success("Contact details updated successfully", {
              position: "bottom-left",
            });
            setActivePage("intro");
          }
          dispatch(stopPageLoad());
        })
        .catch((e) => {
          console.log(e);
          dispatch(stopPageLoad());
        });
    }
  };

  //   const countryHandle = (e) => {
  //     let obj = JSON.parse(e.target.value);
  //     setContactData((prevState) => ({
  //       ...prevState,
  //       country: obj,
  //       state: null,
  //       city: null,
  //     }));
  //   };

  //   const stateHandle = (e) => {
  //     let obj = JSON.parse(e.target.value);
  //     setContactData((prevState) => ({
  //       ...prevState,
  //       state: obj,
  //       city: null,
  //     }));
  //   };

  //   const handleSelectedValueNationality = (value) => {
  //     setShowLabel(value.value);
  //     setContactData({
  //       ...contactData,
  //       nationality: {
  //         country_code: value.country_code,
  //         id: value.id,
  //         name: value.value,
  //       },
  //     });
  //   };

  //   const handleChange = (value) => {
  //     if (value?.key.length === 1) {
  //       setNationalityList(countryList?.data.filter((item) => (item.name.toLowerCase().startsWith(value?.key))));
  //     }
  //     else {
  //       setNationalityList(countryList?.data);
  //     }
  //   }
  // console.log(countryList)

  const phoneCountValidation = () => {
    let num = JSON.stringify(contactData.phone);
    let code = JSON.stringify(phoneCount);
    const number = phoneUtil.parse(num, code);
    return phoneUtil.isValidNumber(number);
  };

  const postCodeValidator = (pin_code, countryCode) => {
    return postalCodes.validate(countryCode, pin_code);
  };

  //Website Validation
  const isValidUrl = (url) => {
    // Regular expression for a URL validation
    const urlRegex =
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    const urlWithoutProtocolRegex = /^(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
    return urlRegex.test(url);
  };

  const handleWebsiteChange = (e) => {
    const newWebsite = e.target.value;
    setContactData({
      ...contactData,
      website: newWebsite,
    });
  };

  const isWebsiteValid = isValidUrl(contactData.website);

  console.log(contactData, "fgfgf");
  return (
    <>
      {isPageLoading && <AppLoader />}
      <div className="edit-contact-section">
        <div className="edit-form">
          <h3>Edit contact info</h3>
          <form>
            <div className="title mb-3 mt-3">
              <label className="contact-label">Phone Number</label>
              <MobileInput
                onChange={(value, country, e, formattedValue) => {
                  setContactData({
                    ...contactData,
                    phone: `+${country.dialCode}-${value.slice(
                      country.dialCode.length
                    )}`,
                  });
                  setPhoneCount(country.countryCode);
                }}
                value={contactData.phone}
                error={errorPhoneNumber}
                placeholder="Number"
                inputType="page"
                containerClassName="pt-0"
                onBlur={() => phoneExist(contactData.phone)}
              />
            </div>
            <div className="mb-3 mt-3">
              <label className="contact-label">Birthday</label>
              <div className="">
                <div className="exper">
                  <FormGroup>
                    <DatePickerCustom
                      value={
                        contactData.dob
                          ? new Date(contactData.dob).format("dd/mm/yyyy")
                          : moment()
                              .subtract(18, "years")
                              ._d.format("DD/mmmm/yyyy")
                      }
                      onChange={(date) => {
                        date
                          ? setContactData({
                              ...contactData,
                              dob: moment(date).format("YYYY-MM-DD"),
                            })
                          : setContactData({ ...contactData, dob: null });
                      }}
                      placeholder="Pick"
                      max={moment().subtract(18, "years")._d}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="title mb-3 mt-3">
              <label className="contact-label">Address</label>
              <Input
                value={contactData.address}
                onChange={(e) =>
                  setContactData({ ...contactData, address: e.target.value })
                }
                id="exampleText"
                name="text"
                type="textarea"
                maxLength="200"
              />
            </div>

            <div className="mb-3 mt-3">
              <div className="double-input">
                <div className="exper">
                  <FormGroup>
                    <Label className="contact-label" for="employmentropdown">
                      Nationality
                    </Label>
                    <div className="company-custom-dropdown mb-20">
                      <div className="autocomplete-wrapper portal-auto-complete">
                        {countryList && (
                          <Autocomplete
                            inputProps={{
                              placeholder: "Nationality",
                            }}
                            value={countryInput}
                            items={countryList?.data}
                            getItemValue={(item) => item.name}
                            shouldItemRender={(item, value) =>
                              item.name
                                .toLowerCase()
                                .indexOf(value.toLowerCase()) > -1
                            }
                            renderMenu={(items, value) => {
                              if (items.length > 0) {
                                setNationalityValid(true);
                              } else {
                                setNationalityValid(false);
                              }
                              return (
                                <div className="dropdown">
                                  {items.length === 0
                                    ? `No matches for ${value}`
                                    : items}
                                </div>
                              );
                            }}
                            renderItem={(item, isHighlighted) => (
                              <div
                                className={`item ${
                                  isHighlighted ? "selected-item" : ""
                                }`}
                              >
                                {item.name}
                              </div>
                            )}
                            onChange={(obj, val) => {
                              setCountryInput(val);
                              setContactData({
                                ...contactData,
                                nationality: obj,
                              });
                            }}
                            // onSelect={(val, obj) => {
                            //   setCountryInput(val);
                            //   setContactData({
                            //     ...contactData,
                            //     nationality: obj,
                            //   });
                            // }}
                            onSelect={(val, obj) => {
                              if (val === `No matches for ${countryInput}`) {
                                // Clear the input and reset nationality
                                setCountryInput("");
                                setContactData({
                                  ...contactData,
                                  nationality: null, // Reset nationality
                                });
                              } else {
                                setCountryInput(val);
                                setContactData({
                                  ...contactData,
                                  nationality: obj,
                                });
                              }
                            }}
                            wrapperStyle={{}}
                          />
                        )}
                      </div>
                    </div>
                    {!nationalityValid ? (
                      <>
                        <p style={{ color: "red" }}>
                          Invalid nationality please select from dropdown
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </FormGroup>
                </div>
                <div className="exper">
                  <FormGroup>
                    <Label className="contact-label" for="employmentropdown">
                      Country/Region
                    </Label>
                    <div className="company-custom-dropdown mb-20">
                      <div className="autocomplete-wrapper portal-auto-complete company_auto_drop">
                        {locationList && (
                          <Autocomplete
                            inputProps={{
                              placeholder: "Location",
                            }}
                            value={locationInput}
                            items={locationList}
                            getItemValue={(item) => item.name}
                            shouldItemRender={(item, value) =>
                              item.name
                                .toLowerCase()
                                .indexOf(value.toLowerCase()) > -1
                            }
                            renderMenu={(items, value) => {
                              if (items.length > 0) {
                                setIsRegionValid(true);
                              } else {
                                setIsRegionValid(false);
                              }
                              return (
                                <div className="dropdown">
                                  {items.length === 0
                                    ? `No matches for ${value}`
                                    : items}
                                </div>
                              );
                            }}
                            renderItem={(item, isHighlighted) => (
                              <div
                                className={`item ${
                                  isHighlighted ? "selected-item" : ""
                                }`}
                              >
                                {item.name}
                              </div>
                            )}
                            onChange={(obj, val) => {
                              setLocationInput(val);
                              setContactData({
                                ...contactData,
                                location: obj.name,
                                country: obj.country_id
                                  ? {
                                      id: obj.country_id,
                                      code: obj.country_code,
                                    }
                                  : null,
                                state: obj.state_id ? obj.state_id : null,
                                city: obj.city_id ? obj.city_id : null,
                              });
                              setCountryCode(
                                obj.country_code ? obj.country_code : null
                              );
                            }}
                            onSelect={(val, obj) => {
                              setLocationInput(val);
                              setContactData({
                                ...contactData,
                                location: obj.name,
                                country: obj.country_id
                                  ? {
                                      id: obj.country_id,
                                      code: obj.country_code,
                                    }
                                  : null,
                                state: obj.state_id ? obj.state_id : null,
                                city: obj.city_id ? obj.city_id : null,
                              });
                              setCountryCode(
                                obj.country_code ? obj.country_code : null
                              );
                            }}
                            wrapperStyle={{}}
                          />
                        )}
                      </div>
                    </div>
                    {!isRegionValid ? (
                      <>
                        <p style={{ color: "red" }}>
                          Invalid region please select from dropdown
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="mb-3 mt-3">
              <div className="">
                {/* <div className="exper">
                <FormGroup>
                  <Label for="employmentropdown">City</Label>
                  {false ? (
                    <Dropdown label="Select" />
                  ) : (
                    <Input id="employmentropdown" name="select" type="select">
                      <option value="">Select</option>
                      <option value="1">Option 1</option>
                      <option value="2">Option 2</option>
                      <option value="3">Option 3</option>
                    </Input>
                  )}
                </FormGroup>
              </div> */}
                <div className="exper">
                  <label className="contact-label">Post code</label>
                  <Input
                    type="text"
                    placeholder=""
                    maxLength="20"
                    value={contactData.pin_code}
                    onChange={(e) => {
                      setContactData({
                        ...contactData,
                        pin_code: e.target.value,
                      });
                      if (
                        postCodeValidator(e.target.value, countryCode) === true
                      ) {
                        setErrorPostCode("");
                      } else setErrorPostCode("Invalid Post Code.");
                    }}
                    // onBlur={ ()=>}
                  />
                  {errorPostCode && (
                    <p className="label-input100 text-danger">
                      {errorPostCode}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="contact-info website-info mb-3 mt-4">
              <h6>Website</h6>
              <Input
                type="text"
                placeholder=""
                // maxLength="20"
                value={contactData?.website}
                onChange={handleWebsiteChange}
                onBlur={() => setWebsiteTouched(true)}
                invalid={!isWebsiteValid && websiteTouched}
              />
              {!isWebsiteValid && websiteTouched && (
                <FormFeedback>
                  Please enter a valid website URL (e.g., http://example.com or
                  www.example.com)
                </FormFeedback>
              )}
              <h6>Email</h6>
              <span className="addButton orange-clr">{profileData?.email}</span>
            </div>
          </form>
        </div>

        {!profileData?.np && <JoinUsSection />}
        <button
          className="close-btn"
          onClick={() => setActivePage("intro")}
          style={{
            border: `1px solid ${talentPoolData?.theme}`,
            color: `${talentPoolData?.theme}`,
          }}
        >
          Close
        </button>
        <button
          disabled={!nationalityValid || !isRegionValid}
          className="save-btn"
          onClick={(e) => {
            if (isWebsiteValid) {
              handleSubmit(e);
            }
          }}
          style={{ background: `${talentPoolData?.theme}` }}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default EditContactInfo;
