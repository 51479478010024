import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getUserSetting,
  updateSettingDetails,
} from "../../../store/setting/SettingAction";
import { call } from "../../../api/apiCall";
import { useNavigate } from "react-router-dom";
import ConfirmPassCloseAccount from "./ConfirmPassCloseAccount";

const CloseAccountModal = (props) => {
  const { modal, toggle, title, setCloseAccountModal } = props;
  const dispatch = useDispatch();
  const [responseMsg, setResponseMsg] = useState("");
  const [passModal, setPassModal] = useState(false);
  const navigate = useNavigate();
  let token = window.localStorage.getItem("accessToken");

  const updateMode = async () => {
    try {
      let value = 1;
      let updateData = {
        setting: [
          {
            setting_master_id: 25,
            value: value + "",
          },
        ],
      };
      dispatch(updateSettingDetails(updateData, token)).then((res) => {
        dispatch(getUserSetting(0, token));
        setResponseMsg(res.message);
        setTimeout(() => {
          setResponseMsg(false);
        }, 3000);
      });

      localStorage.clear();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal isOpen={modal} size="lg" style={{ maxWidth: "500px" }}>
        <div className="popup">
          <div className="setting-popup">
            <ModalHeader toggle={toggle} id="account-modal">
              {title}
            </ModalHeader>
            <ModalBody>
              <p>
                Please note that your NobelPage account including WorkerPool
                profile and related pages, groups, if any, will be permanently
                deleted.
              </p>
              <div className="text-center my-3">
                <div
                  className="btn btn-blue"
                  onClick={() => setPassModal(true)}
                >
                  Close Account
                </div>
              </div>
              {responseMsg && (
                <label className="label-input100 text-success">
                  {responseMsg}
                </label>
              )}
            </ModalBody>
          </div>
        </div>
      </Modal>
      {passModal && (
        <ConfirmPassCloseAccount
          modal={passModal}
          toggle={() => setPassModal(!passModal)}
          title="Enter Password"
          setCloseAccountModal={setCloseAccountModal}
        />
      )}
    </div>
  );
};

export default CloseAccountModal;
